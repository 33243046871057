import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Posts from "../components/Posts"

const BlogIndex = ({ data, location, pageContext }) => {
  const posts = data.allMarkdownRemark.edges

  if (posts.length === 0) {
    return (
      <Layout>
        <Seo title="All posts" />
        <p>No blog posts found.</p>
      </Layout>
    )
  }

  return (
    <Layout>
      <Seo title="All posts" />
      <Posts posts={posts} pageContext={pageContext} />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            description
            category
            image
          }
        }
      }
    }
  }
`
